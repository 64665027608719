<template>
	<div>
		<div class="good_add good_addclass">
			<header>
				<span class="back" @click="headback">
					<i class="el-icon-arrow-left" style="font-size:14px;"></i>
					返回
					<i class="line">|</i>
				</span>
				<span class="titile">{{textid == 0 ? "添加分类" : "编辑分类"}}</span>
			</header>
			<nav>
				<div class="class_wrap">
					<div class="class_container">
						<div class="class_head">
							<p>{{textid == 0 ? "添加分类" : "编辑分类"}}</p>
						</div>
						<div class="class_addnav">
							<el-form label-width="160px" :rules="sifiedRules" :model="sifiedForm" ref="sifiedForm">
								<el-form-item label="父级名称">
									<div class="class_parent">
										<el-cascader filterable clearable v-model="value" :options="optionparent" ref="cascader"
											:props="{ value: 'id',label: 'label',children: 'children', checkStrictly: true,emitPath: false}"
											@change="handleChange">
										</el-cascader>
									</div>
								</el-form-item>
								<el-form-item label="PC端名称" prop="name">
									<div class="class_input">
										<el-input v-model="sifiedForm.name"></el-input>
									</div>
								</el-form-item>
								<el-form-item label="移动端名称" prop="app_name">
									<div class="class_input">
										<el-input v-model="sifiedForm.app_name"></el-input>
									</div>
								</el-form-item>
								<el-form-item label="SEO关键词" prop="seo_keyword">
									<div class="class_input">
										<el-input v-model="sifiedForm.seo_keyword" type="textarea" maxlength="100"
											show-word-limit placeholder="注：一般不超过100个字符"></el-input>
									</div>
								</el-form-item>
								<el-form-item label="SEO描述" prop="seo_desc">
									<div class="class_input">
										<el-input v-model="sifiedForm.seo_desc" type="textarea" maxlength="100"
											show-word-limit placeholder="注：一般不超过100个字符"></el-input>
									</div>
								</el-form-item>
								<el-form-item label="排序" prop="sort">
									<div class="class_input">
										<el-input v-model="sifiedForm.sort" type="number" placeholder="排序值越小排序越靠前"></el-input>
									</div>
								</el-form-item>
								<el-form-item label="分类图标PC端">
									<div class="class_input">
										<div class="d-flex align-items-center">
											<div class="skeletonize" @click="photoVisible('pc_icon')">
												<img v-if="sifiedForm.pc_icon" :src="sifiedForm.pc_icon" alt="">
											    <i class="el-icon-plus" v-else></i>
											</div>
										</div>
									</div>
								</el-form-item>
								<el-form-item label="分类图标移动端">
									<div class="class_input">
										<div class="d-flex align-items-center">
											<div class="skeletonize" @click="photoVisible('app_icon')">
												<img v-if="sifiedForm.app_icon" :src="sifiedForm.app_icon" alt="">
											    <i class="el-icon-plus" v-else></i>
											</div>
										</div>
									</div>
								</el-form-item>
								<!-- 暂时隐藏误删 -->
								<template v-if="classhidden">
									<el-form-item label="分类广告">
										<div class="class_input">
											<div class="d-flex align-items-center">
												<div class="skeletonize">
													<i class="el-icon-plus"></i>
												</div>
											</div>
										</div>
									</el-form-item>
									<el-form-item label="分类广告链接">
										<div class="advert">
											<el-input v-model="sifiedForm.advertising" readonly disabled>
												<div slot="append" @click="linkVisible=true">
													选择链接
												</div>
											</el-input>
										</div>
									</el-form-item>
								</template>
								<el-form-item label="是否显示">
									<el-radio-group v-model="sifiedForm.is_show">
										<el-radio :label="1">显示</el-radio>
										<el-radio :label="0">隐藏</el-radio>
									</el-radio-group>
								</el-form-item>
							</el-form>
							<div class="class_container"></div>
						</div>
					</div>
				</div>
			</nav>
			<diy-footer-button>
			    <el-button type="primary" @click="catsubmit('sifiedForm')" v-if="hasPerm(['goods.category.store','goods.category.update'])">保存</el-button>
			    <el-button @click="headback()">取消</el-button>
			</diy-footer-button>
		</div>
		<!-- 图片选择器 -->
		<pictureSelect ref="imglist" :multipled="is_multiple"  @change="imgbrand($event)"></pictureSelect>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				textid: this.$route.params.id,
				classhidden: false,
				is_multiple:false,
				value: [],
				optionparent: [],
				level: 0,
				sifiedForm: {
					name: '', //pc端名称
					app_name: '', //移动端名称
					seo_keyword: '', //seo关键词
					seo_desc: '', //seo描述
					sort: 100, //排序
					pc_icon: '',//图标
					is_show: 1, //是否显示隐藏
					advertising: '', //广告链接
					app_icon:'',	//移动端icon
				},
				sifiedRules: {
					name: {
						required: true,
						message: '请输入PC端名称',
						trigger: 'blur'
					},
					app_name: {
						required: true,
						message: '请输入移动端名称',
						trigger: 'blur'
					},
					sort: {
						required: true,
						message: '请输入排序数值',
						trigger: 'blur'
					}
				},
				linkVisible: false,
				mobileActive:this.$route.query.active_id||this.$route.query.activity_id||'0',
				photoType:'',
			};
		},
		components: {},
		created() {

		},
		mounted() {
			if(this.textid > 0){
				this.loopcatdetail();
			}else{
				this.parentCatlist();
			}
			
		},
		methods: {
			loopcatdetail() {
				this.$get(this.$apis.infoDetail + this.textid).then(res => {
					if (res.code == 200) {
						this.sifiedForm = res.data;
						if(res.data.level){
							this.level = res.data.level
						}else{
							this.level = 0
						}
						if(this.level == 1){
							this.$nextTick(function(){
								this.$set(this,"optionparent",[])
							})
						}
						this.$set(this,"value",res.data.parent_id[res.data.parent_id.length - 1])
						this.parentCatlist();
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			parentCatlist() {
				this.$get(this.$apis.catparentList+this.mobileActive).then(res => {
					if (res.code == 200) {
						this.optionparent = this.getTreeData(res.data.list)
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			getTreeData(data) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].children.length < 1) {
						data[i].children = undefined;
					} else {
						this.getTreeData(data[i].children);
					}
				}
				return data;
			},
			headback() {
				window.history.go(-1)
			},
			handleChange(value) {
				this.parent_id = value;
			},
			photoVisible(type) {
				this.photoType=type;
				this.$refs.imglist.photoVisible = true;
			},
			imgbrand(val){
				this.sifiedForm[''+this.photoType]= val[0].file_url;	
			},
			// 保存
			catsubmit(formName){
				let data = {
					name : this.sifiedForm.name,
					level:this.level,
					parent_id:this.value,
					app_name : this.sifiedForm.app_name,
					pc_icon: this.sifiedForm.pc_icon,
					sort : this.sifiedForm.sort,
					is_show : this.sifiedForm.is_show,
					seo_keyword: this.sifiedForm.seo_keyword,
					seo_desc: this.sifiedForm.seo_desc,
					pic_url:"",
					pc_advert_url: "",
					big_pic_url:"",
					advert_pic:"",
					advert_url:"",
					activity_id:this.mobileActive,
					app_icon:this.sifiedForm.app_icon,
				};
				//console.log(this.value)
				if(this.value.length == 0){
					data.level = 0
					data.parent_id = 0
				}
				// //console.log(data);
				// return false;
				if(this.textid == 0){
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.$post(this.$apis.infoList,data).then(res => {
								if(res.code == 200){
									// //console.log(res)
									this.$message({
										type: 'success',
										duration: 1500,
										message: '保存成功',
										onClose :()=>{
											this.$router.go(-1)
										}
									});
								}else{
									this.common.message(this, res.message);
								}
							}).catch(err => {
								// //console.log(err)
								this.common.message(this, err.message);
							});
						}else{
							return false;
						}
					})
				}else{
					this.$refs[formName].validate((valid) => {
						if (valid) {
							this.$put(this.$apis.infoList + this.textid,data).then(res => {
								if(res.code == 200){
									// //console.log(res)
									this.$message({
										type: 'success',
										duration: 1500,
										message: '修改成功',
										onClose :()=>{
											this.$router.go(-1);
										}
									});
								}else{
									this.common.message(this, res.message);
								}
							}).catch(err => {
								// //console.log(err)
								this.common.message(this, err.message);
							});
						}else{
							return false;
						}
					})
				}
			},
		}
	};
</script>

<style scoped>
.good_add head{
	margin-bottom: 0 !important;
}
	.good_addclass header{margin-bottom: 0;}

	.el-radio__input.is-checked .el-radio__inner {
		background-color: #fff !important;
		width: 18px;
		height: 18px;
	}

	.el-radio__input .el-radio__inner {
		width: 18px;
		height: 18px;
	}

	.el-radio__input.is-checked .el-radio__inner::after {
		width: 8px;
		height: 8px;
		background-color: var(--fontColor);
	}

	.box_right {
		width: calc(100% - 106px);
		height: 100vh;
		margin-left: 106px;
		box-sizing: border-box;
		display: flex;
		min-height: 0;
		flex: 1 1 auto;
	}

	.goods_left {
		width: 134px;
		flex: 0 0 134px;
		background: #fff;
		height: 100vh;
		z-index: 10;
		border-right: 1px solid #eee;
		position: fixed;
		top: 0;
		left: 106px;
		box-sizing: border-box;
		margin-top: 50px;
	}

	.goods_headline {
		height: 50px;
		line-height: 50px;
		padding-left: 23px;
		font-size: 14px;
		color: #333;
		border-bottom: 1px solid #eee;
		padding-top: 0;
	}

	nav {
		background: #fff;
		display: flex;
		flex: 1;
		margin-top: 20px;
		padding-bottom: 60px;
	}

	.class_wrap {
		display: flex;
		flex: 1;
		flex-direction: column;
	}

	.class_container {
		background: #fff;
		border-radius: 4px;
		position: relative;
		margin: 24px;
		transition: all .2s ease-in-out;
		margin-top: 0;
	}

	.class_head {
		border-bottom: 1px solid #f2f2f2;
		padding: 8px 20px;
		line-height: 1;
	}

	.class_head p {
		display: inline-block;
		width: auto;
		line-height: 28px;
		font-size: 16px;
		color: #333;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		font-weight: 700;
	}

	.class_addnav {
		margin: 20px;
	}

	.class_parent>.el-cascader {
		width: 400px;
	}
	.el-cascader /deep/ .el-input {
		width: 400px;
	}

	.class_input .el-input {
		width: 400px;
	}

	.class_input .el-textarea {
		width: 400px !important;
		height: 150px;
	}

	.class_input .el-textarea .el-textarea__inner {
		height: 150px;
		resize: none;
	}

	.class_input .el-textarea .el-input__count {
		line-height: 1;
	}

	.class_input>p {
		color: #d9534f;
	}

	.sortTips {
		color: #636c72;
		font-size: 13px;
	}

	.skeletonize {
		width: 50px;
		height: 50px;
		line-height: 50px;
		margin-right: 10px;
		border: 1px dashed #ededed;
		text-align: center;
		color: #dad9d9;
		float: left;
		position: relative;
		cursor: pointer;
		background: white;
	}

	.skeletonize_toast {
		font-size: 12px;
		color: #999;
		padding: 4px 0;
		box-sizing: border-box;
		line-height: 1;
	}
	.skeletonize img{display: block;width: 100%;height: 100%;object-fit: cover;}
	.advert .el-input {
		width: 350px;
	}

	.advert .el-input .el-input-group__append {
		background-color: #fff;
		color: #292b2c;
		cursor: pointer;
	}

	.link_wrap {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		margin: 20px 0;
	}

	.link_head {
		display: flex;
	}

	.link_head>span {
		padding: 5px 7px;
		margin-bottom: 0;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.25;
		color: #464a4c;
		text-align: center;
		background-color: #eceeef;
		border: 1px solid rgba(0, 0, 0, .15);
		border-radius: 4px;
		border-bottom-right-radius: 0;
		border-top-right-radius: 0;
		white-space: nowrap;
		vertical-align: middle;
		display: flex;
		justify-content: center;
		flex-direction: column;
	}

	.link_head>.el-select {
		display: flex;
		width: 400px;
	}

	.link_head>.el-select>.el-input>.el-input__inner {

		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		border-left: none;
	}

	.linkTips {
		font-size: 13px;
		color: var(--fontColor);
		margin-top: 10px;
	}
</style>
